@import '../../me-benefit/styles/me-benefit';

.me-benefits {
  padding-top: 64px;
  background-image: linear-gradient(to top, $andes-white, $andes-bg-secondary);
  padding-bottom: 0;
  margin-bottom: -30px;

  .me-benefits-title {
    @include font($font-size-28, normal, 1.15, $text-color);

    width: 312px;
    text-align: center;
    margin: 0 0 45.5px;
  }

  .me-benefits-content {
    margin: auto;
    padding-bottom: 48px;
  }

  .me-benefit {
    margin-bottom: 20px;
  }
}

@media ($breakpoint-medium) {
  .me-benefits {
    overflow: hidden;
    padding: 120px 0;
    padding-bottom: 64px;
    margin-bottom: 0;

    .me-benefits-title {
      margin: auto;
      width: auto;
      height: auto;
      max-width: 512px;
      font-family: 'Proxima Nova';
      font-size: 32px;
      line-height: 1;
      color: $text-color;
      margin-bottom: 46px;
    }

    .me-benefits-content {
      max-width: 1040px;
      padding: 0 64px;
      box-sizing: border-box;
      margin: auto;
    }
  }
}

@media ($breakpoint-large) {
  .me-benefits {
    overflow: hidden;
    padding-bottom: 80px;

    .me-benefits-title {
      margin: auto;
      width: 549px;
      font-family: 'Proxima Nova';
      text-align: center;
      color: $text-color;
      font-size: 32px;
      margin-bottom: 36px;
    }

    .me-benefits-content {
      padding: 0 64px;
      width: 1024px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media ($breakpoint-larger) {
  .me-benefits {
    overflow: hidden;
    padding-top: 92px;

    .me-benefits-content {
      width: 1040px;
      margin: auto;
      padding: 0;
      overflow: hidden;
    }
  }
}
