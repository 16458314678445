@import '@styles/app';
@import '@styles/envios';

$soft-grey: #666;
$soft-black: #333;

@import '@styles/variables';

// TRANSITIONS

@function delayed-transition($child-number, $delay, $base: 0) {
  $calculated-seconds: $base + ($child-number * $delay);
  @return ($calculated-seconds + s);
}

@function fixed-delay($child-number) {
  $fixed: 0.35;
  $base: 0.6;
  @return delayed-transition($child-number, $fixed, $base);
}

@mixin delayed-childs($elements, $duration: 0.25s) {
  $i: 0;
  $i: $i + 1;

  &.delayed:nth-child(1),
  &.delayed:nth-child(2) {
    transition: transform $duration ease-in-out fixed-delay($i),
      opacity $duration ease-in-out fixed-delay($i);
  }

  &.delayed:nth-child(3),
  &.delayed:nth-child(4) {
    transition: transform $duration ease-in-out fixed-delay($i),
      opacity $duration ease-in-out fixed-delay($i);
  }
}

.quiero-sumarme .transitionable.header {
  transform: translateY(20%) scale(1);
}

.transitionable {
  transform: translateY(10%) scale(1);
  opacity: 0;
  transition: transform 0.25s ease-in-out, opacity 0.35s ease-in-out;

  &.benefit {
    transform: translateY(5%) scale(1);
  }

  &.visible,
  &.visible.header {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@media (min-width: $breakpoint-desktop) {
  .transitionable {
    $duration: 0.35s;
    @include delayed-childs(4);

    transition: transform $duration ease-in-out, opacity $duration ease-in-out;
  }
}

// END TRANSITIONS

@import './components/body/body';
@import './components/benefits/benefits';

main {
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga', 'kern';
}

* {
  margin: 0;
}

a {
  cursor: pointer;
  color: $andes-blue-500;
  text-decoration: none;
}

.custom-nav-logo {
  background: url('~@assets/images/MELayout/logo-yellow-webm.svg');
  background-repeat: no-repeat;
}

.nav-bounds {
  margin-left: 15px;
}

.mercado-envios-header {
  background-color: #FFE600;
  height: 49px;
  border-bottom: none;
}

@media (min-width: $breakpoint-desktop) {
  .nav-bounds {
    margin-left: 52px;
  }
}

@media (min-width: $large-tablet) {
  .custom-nav-logo {
    background: url('~@assets/images/MELayout/logo-meli-desktop.svg');
    background-repeat: no-repeat;
    width: 132px;
    background-size: contain;
    height: 32px;
    top: 12px;
    left: 0;
  }

  .mercado-envios-header {
    height: 57px;
  }
}

button:focus {
  outline: 0;
}

.custom-nav-footer {
  background-color: $andes-bg-secondary;
  position: relative;
  color: #999;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica,
    Roboto, Arial, sans-serif;
  box-sizing: border-box;

  a {
    text-decoration: none;
  }

  .nav-footer-copyright {
    span {
      @include font(12px, normal, 1.42, #999);

      vertical-align: bottom;
      text-align: left;
      width: 484px;
      height: 17px;
      color: #999;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    margin: 0 16px;
    border-top: solid 1px #dedede;
    content: '';
  }
}

.custom-nav-links {
  display: none;
}

@media (min-width: $breakpoint-desktop) {
  .custom-nav-footer {
    background-color: #f7f7f7;
    border-top: 1px solid #eee;
    line-height: 1;
    font-size: 13px;
    font-weight: 400;
  }
}

@media (min-width: $breakpoint-desktop) {
  .media-small {
    display: none;
  }

  .media-large {
    display: block;
  }

  .custom-nav-footer {
    background-color: $andes-white;
    border-top: solid 1px #eee;
    color: #999;
    font-size: 13px;
    overflow: hidden;
    width: 100%;
    height: 71px;
    padding-top: 16px;
    font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica,
      Roboto, Arial, sans-serif;
    box-sizing: border-box;

    a {
      text-decoration: none;
    }

    &::after {
      display: none;
      content: '';
    }
  }

  .nav-footer-copyright {
    span {
      @include font(12px, normal, normal, #999);

      vertical-align: bottom;
      width: 100%;
      text-align: left;
    }
  }

  .custom-nav-links {
    display: block;

    a {
      @include font(14px, normal, normal, #333);

      text-align: left;
    }

    .work-with-us {
      width: 129px;
      height: 17px;
      margin-right: 16px;
    }

    .tyc {
      width: 146px;
      height: 16px;
      margin-right: 14px;
    }

    .privacy-policy {
      width: 138px;
      height: 17px;
      margin-right: 16px;
    }

    .help {
      width: 39px;
      height: 17px;
    }
  }

  @media (width >= 1024px) {
    .nav-footer-copyright {
      margin: 0;
      position: static;
    }
  }
}

@media (max-width: $breakpoint-desktop) {
  .media-small {
    display: block;
  }

  .media-large {
    display: none;
  }
}

@media (min-width: $large-desktop) {
  .nav-bounds {
    width: 1200px;
    margin: 0 auto;
  }
}
