@import '@components/envios/dispatch-steps/styles/dispatch-steps';
@import '@components/envios/me-benefits/styles/me-benefits';
@import '@components/envios/fs-benefits/styles/fs-benefits';

.benefits {
  .card-container {
    background-color: $andes-white;
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

.outstanding {
  background-image: url('~@assets/images/envios/fs-benefits/MLA/ilus-destacados.png');
}

.accesible {
  background-image: url('~@assets/images/envios/fs-benefits/MLA/ilus-shipping.png');
}

.pending {
  background-color: #d8d8d8;
}

@media (min-width: $breakpoint-desktop) {
  .benefits {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $andes-bg-secondary;

    .push-benefits {
      height: 1124px;
      width: 100%;
    }
  }
}

@media (width >= 1280px) {
  .benefits {
    .push-benefits {
      height: 808px;
    }
  }
}
