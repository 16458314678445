$dispatch-step-delay: 0.75s;

@function delayed-calc($child-number, $delay, $base: 0) {
  $calculated-seconds: $base + ($child-number * $delay);
  @return ($calculated-seconds);
}

@mixin delayed-dispatch-steps($elements, $duration: 0.2s) {
  $base: 0.15;
  $baseSubTitle: 0.2;

  @for $i from 0 to $elements {
    .dispatch-step:nth-child(#{$i + 1}) {
      .title,
      .image {
        transition-delay: delayed-calc($i, $duration, $base) + s;
      }

      .sub-title,
      .sub-title a {
        transition-delay: delayed-calc($i, $duration, $baseSubTitle) + s;
      }

      .image::before {
        transition-delay: (delayed-calc($i, $duration, $base) + 0.5) + s;
      }

      svg {
        transition-delay: delayed-calc($i, $duration, $base);

        .stroke,
        .fill {
          transition-delay: delayed-calc($i, $duration, 0.45) + s;
        }
      }
    }
  }
}

.dispatch-steps-container .transitionable.header {
  transform: translateY(20%) scale(1);
}

.dispatch-steps-container {
  background-color: $andes-bg-secondary;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.functionality {
  overflow: hidden;
  width: 100%;

  h2 {
    @include font(22px, normal, 1, $text-color);

    padding-top: 32px;
  }

  .dispatch-steps {
    @include delayed-dispatch-steps(6, 0.2);

    box-sizing: border-box;
    padding: 32px 20px 0 40px;
    position: relative;

    .dispatch-step {
      background-color: transparent;
      margin: auto;
      width: 312px;
      margin-bottom: 32px;
      text-align: left;
      display: flex;

      .text-container {
        width: 145px;
        display: inline-block;
        text-align: left;
        margin: auto;
        margin-left: 10px;
        position: relative;
      }

      .title {
        @include font(20px, normal, 1.2, $gray-lighter);

        height: 24px;
        transition: 0.3s linear;
        transition-property: color;
      }

      .sub-title {
        @include font(16px, 300, 1.25, $text-color);

        transition: 0.2s linear;
        transition-property: color;
        margin-top: -6px;

        &.sub-title_last {
          margin-top: -16px;
        }

        a {
          color: $gray-lighter;
          transition: 0.2s linear;
          transition-property: color;
        }

        @media screen and (min-width: $breakpoint-desktop) {
          @include font(20px, 300, 1.25, $gray-lighter);
        }
      }

      .image {
        position: relative;
        object-fit: contain;
        border: solid 2px transparent;
        width: 88px;
        display: inline-block;

        .image-svg {
          width: 68px;
          height: 68px;
          border-radius: 50%;
          background: $andes-white;
          margin: 0 auto;
          box-shadow: 0 7px 7px 0 rgb(40 46 87 / 8%);
          position: relative;

          .printer {
            background-image: url('~@assets/images/envios/dispatch-steps/icon-printer-black.svg');
          }

          .box {
            background-image: url('~@assets/images/envios/dispatch-steps/icon-box-black.svg');
          }

          .warehouse {
            background-image: url('~@assets/images/envios/dispatch-steps/icon-warehouse.svg');
          }

          .place {
            background-image: url('~@assets/images/envios/dispatch-steps/icon-place-black.svg');
          }

          .truck {
            background-image: url('~@assets/images/envios/dispatch-steps/icon-truck.svg');
          }

          .printer,
          .box,
          .warehouse,
          .place,
          .truck {
            background-repeat: no-repeat;
            background-size: contain;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 14px;
            left: 14px;
          }

          svg {
            position: relative;
            top: 9px;
            left: 50%;
            transform: translateX(-50%);
          }

          &.image-svg--top {
            svg {
              top: 0;
            }
          }
        }

        .image-css {
          float: left;
          margin: auto;
          box-sizing: border-box;
          position: relative;
          width: 23px;
          height: 23px;
          background-color: #f7d032;
          margin-right: 16px;
          background-repeat: no-repeat;
          background-size: cover;
          border: solid 2px $andes-bg-secondary;
          border-radius: 50%;

          svg {
            position: relative;
            top: -2px;
            left: -2px;
          }
        }

        svg {
          .fill {
            transition: $dispatch-step-delay linear;
            transition-property: fill;
          }

          .stroke {
            transition: $dispatch-step-delay linear;
            transition-property: stroke;
          }
        }
      }
    }

    .dispatch-step:not(:last-child) {
      .image::after {
        content: '';
        height: 50px;
        margin: 0 auto;
        width: 1px;
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        border-left: 1px dashed $andes-blue-500;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .dispatch-steps-container {
    padding: 0;
  }

  .functionality {
    h2 {
      @include font(32px, normal, 1, $text-color);

      padding-top: 70px;
    }

    .dispatch-steps {
      overflow: hidden;
      padding: 32px 50px 0;
      width: 746px;
      margin: auto;

      .dispatch-step {
        background-color: transparent;
        margin: auto;
        width: 200px;
        height: 189px;
        float: left;
        margin-left: 23px;
        display: block;

        &:first-child {
          margin-left: 0;
        }

        .text-container {
          text-align: center;
          width: auto;
          margin-top: 24px;
          margin-left: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin-top: 0;
            width: 180px;
          }
        }

        .image {
          width: 100%;

          .image-svg {
            display: block;
            width: 85px;
            height: 85px;

            svg {
              top: 18px;
            }

            .printer,
            .box,
            .warehouse,
            .place,
            .truck {
              width: 48px;
              height: 48px;
              top: 18.5px;
              left: 18.5px;
            }
          }

          .image-css {
            float: none;
            position: absolute;
            right: 42px;
            top: 68px;
            margin-right: 0;
          }
        }
      }

      .dispatch-step:not(:last-child) {
        .image::after {
          content: '';
          height: 0;
          margin: 0 auto;
          width: 187px;
          position: absolute;
          bottom: 46%;
          left: 148px;
          right: 0;
          border-top: 1px dashed $andes-blue-500;
        }
      }
    }
  }
}

@media screen and (min-width: $large-tablet) {
  .dispatch-steps {
    padding: 32px 50px 0;
  }
}

@media (min-width: $large-tablet) {
  .dispatch-steps {
    width: 956px;

    .dispatch-step {
      width: 270px;

      &:not(:last-child) .image::after {
        width: 199px;
        left: 183px;
      }

      .text-container {
        width: 270px;
      }
    }
  }
}

//override functionality to start transitions.
.functionality.visible {
  .dispatch-steps {
    .dispatch-step:not(:last-child) .image::before {
      height: 100px;
    }

    .dispatch-step .title {
      color: $text-color;
    }

    .dispatch-step .sub-title {
      color: $text-color;
    }

    .dispatch-step .sub-title a {
      color: $andes-blue-500;
    }

    .dispatch-step .image {
      border: solid 2px $andes-bg-secondary;
      opacity: 1;
    }

    .dispatch-step {
      svg {
        .fill {
          fill: $red-dark;
        }

        .stroke {
          stroke: $red-dark;
        }
      }
    }
  }
}
