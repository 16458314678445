.me-benefit {
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 7px 7px 0 rgb(40 46 87 / 8%);
  width: 300px;
  box-sizing: border-box;
  height: auto;
  text-align: center;
  margin: auto;

  .me-benefit-title {
    @include font(20px, 600, 1.2, #04204c);

    text-align: center;
    margin-bottom: 8px;
    height: auto;
    width: 100%;
  }

  .me-benefit-text {
    @include font(18px, 500, 1.22, #666);

    text-align: center;
  }

  .me-benefit-image {
    width: 84px;
    height: 84px;
    margin: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.payment .me-benefit-image {
    background-image: url('~@assets/images/envios/me-benefits/icon-payment-yellow.svg');
  }

  &.secure .me-benefit-image {
    background-image: url('~@assets/images/envios/me-benefits/icon-secure-yellow.svg');
  }

  &.tracking .me-benefit-image {
    background-image: url('~@assets/images/envios/me-benefits/icon-tracking-yellow.svg');
  }

  &.coordination .me-benefit-image {
    background-image: url('~@assets/images/envios/me-benefits/icon-coordination-yellow.svg');
  }

  &.cart .me-benefit-image {
    background-image: url('~@assets/images/envios/me-benefits/icon-cart-yellow.svg');
  }

  &.reputation .me-benefit-image {
    background-image: url('~@assets/images/envios/me-benefits/icon-cellphone-check-yellow.svg');
  }
}

@media ($breakpoint-medium) {
  .me-benefit {
    max-width: 640px;
    width: auto;
    height: auto;
    padding: 40px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 7px 7px 0 rgb(40 46 87 / 8%);

    .me-benefit-image {
      float: none;
      margin-bottom: 0;
    }

    .me-benefit-content {
      float: none;
      box-sizing: border-box;

      .me-benefit-title {
        width: auto;
        max-width: 512px;
        margin: auto;
        height: auto;
        margin-bottom: 8px;
        text-align: center;
      }

      .me-benefit-text {
        width: auto;
        height: auto;
        max-width: 512px;
        margin: auto;
        text-align: center;
      }
    }
  }
}

@media ($breakpoint-large) {
  .me-benefit {
    width: 428px;
    height: auto;
    padding: 40px;
    box-sizing: border-box;
    margin-bottom: 40px;

    .me-benefit-image {
      margin: auto;
    }

    .me-benefit-content {
      box-sizing: border-box;

      .me-benefit-title {
        width: auto;
        height: auto;
        margin: auto;
        margin-bottom: 8px;
      }

      .me-benefit-text {
        width: 348px;
        height: 44px;
        margin: auto;
      }
    }

    &:nth-child(odd) {
      float: left;
    }

    &:nth-child(even) {
      float: right;
    }
  }
}

@media ($breakpoint-larger) {
  .me-benefit {
    width: 500px;
    height: 156px;
    padding: 0;
    margin-bottom: 40px;

    .me-benefit-image {
      margin: 36px 28px 36px 32px;
      float: left;
    }

    .me-benefit-content {
      float: right;
      width: 356px;
      padding-top: 33px;
      box-sizing: border-box;

      .me-benefit-title {
        width: 324px;
        height: auto;
        margin: 0;
        margin-bottom: 8px;
        text-align: left;
      }

      .me-benefit-text {
        width: 328px;
        height: 44px;
        margin: 0;
        text-align: left;
      }
    }
  }
}
