// In this file you can create any kind of mixin necessary for the app
// @example: border-radius, etc

@import 'variables';

@mixin breakpoint($point) {
  @if $point == breakpoint__medium {
    @media #{$breakpoint-medium} {
      @content;
    }
  } @else if $point == breakpoint__large {
    @media #{$breakpoint-large} {
      @content;
    }
  } @else if $point == breakpoint__larger {
    @media #{$breakpoint-larger} {
      @content;
    }
  }
}

@mixin font($size: 18, $weight: 300, $lh: 1, $color: $soft-black) {
  font-size: $size;
  font-weight: $weight;
  font-style: normal;
  font-stretch: normal;
  line-height: $lh;
  letter-spacing: normal;
  color: $color;
}
