.banner-now {
  position: relative;
  height: 360px;
  background-repeat: no-repeat, repeat-x;
  background-size: cover;
  background-position-x: 80%;

  &.envelope-es {
    background-image: url('~@assets/images/envios/banner/es/banner.jpg'),
      url('~@assets/images/envios/banner/es/banner_bkg.jpg');
  }

  &.envelope-pt {
    background-image: url('~@assets/images/envios/banner/pt/banner.jpg'),
      url('~@assets/images/envios/banner/pt/banner_bkg.jpg');
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        #2d2527 -11.9%,
        rgb(58 54 55 / 73.1%) 32.36%,
        rgb(95 99 104 / 0%) 79.79%
      ),
      linear-gradient(to left, #2d2525 -12.05%, rgb(95 99 104 / 0%) 57.78%);
  }

  .banner-content {
    margin: 0 auto;
    padding-top: 56px;
    padding-bottom: 56px;
    width: 322px;

    h1 {
      @include font(30px, 300, 1.19, #fff);

      margin-bottom: 15px;
      text-align: center;
    }

    p {
      @include font(20px, 500, 1.3, #fff);

      text-align: center;
      width: 74%;
      margin: auto;
    }

    .btn-cont {
      margin: auto;
      width: 100%;
    }

    .btn-blue {
      width: 312px;
    }
  }
}

@media ($breakpoint-medium) {
  .banner-now {
    min-height: 550px;
    background-position-y: 80%;

    &.envelope-es {
      background-image: url('~@assets/images/envios/banner/es/banner@2x.jpg'),
        url('~@assets/images/envios/banner/es/banner_bkg.jpg');
    }

    &.envelope-pt {
      background-image: url('~@assets/images/envios/banner/pt/banner@2x.jpg'),
        url('~@assets/images/envios/banner/pt/banner_bkg.jpg');
    }

    .banner-content {
      color: #fff;
      margin: auto;
      max-width: 640px;
      width: auto;
      padding-top: 127px;
      padding-bottom: 105px;

      h1 {
        @include font(56px, 500, 1.11, #fff);

        margin: 0;
        width: auto;
        height: 124px;
        margin-bottom: 16px;
        text-align: center;
        color: #fff;
      }

      p {
        @include font(24px, normal, 1.17, #fff);

        width: 641px;
        text-align: center;
      }
    }

    .btn-cont {
      margin-top: 30px;
    }

    .btn-blue {
      @include font(18px, normal, 1, #fff);

      width: 320px;
      height: 48px;
      border-radius: 4px;
      display: table-cell;
      vertical-align: middle;
      background-color: #3483fa;
      text-align: center;
    }
  }
}

@media ($breakpoint-larger) {
  .banner-now {
    background-position-x: 50%;

    .main-col {
      width: 1040px;
      margin: auto;
    }

    .banner-content {
      color: #fff;
      margin-left: 80px;
      padding-top: 127px;
      padding-bottom: 105px;

      h1 {
        @include font(56px, 500, 1.11, #fff);

        margin: 0;
        width: 600px;
        height: 124px;
        margin-bottom: 16px;
        text-align: left;
        color: #fff;
      }

      p {
        @include font(24px, normal, 1.17, #fff);

        width: 521px;
        text-align: left;
        margin: 0;
      }
    }

    .btn-cont {
      margin-top: 30px;
    }

    .btn-blue {
      @include font(18px, normal, 1, #fff);

      width: 320px;
      height: 48px;
      border-radius: 4px;
      display: table-cell;
      vertical-align: middle;
      background-color: #3483fa;
      text-align: center;
    }
  }
}
