.fs-benefit {
  margin: 0 auto;
  margin-bottom: 48px;
  width: 312px;

  &:last-child {
    margin-bottom: 0;

    .image {
      margin-bottom: 0;
    }
  }

  .image {
    height: 226px;
    margin-bottom: 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .foot {
    margin-bottom: 0;
  }

  &:last-child > .foot {
    margin-bottom: 8px;
  }

  .foot-title {
    @include font(24px, normal, 1.17, $text-color);

    margin-bottom: 16px;
    text-align: center;
  }

  .foot-text {
    @include font(18px, 300, 1.22, $text-color);

    text-align: center;

    .separator {
      width: 100%;
      height: 16px;
    }

    a {
      @include font(16px, normal, 1, $andes-blue-500);
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .fs-benefit {
    width: 100%;
    max-width: 632px;
    overflow: hidden;
    margin-bottom: 0;
    padding-bottom: 64px;

    .right {
      width: 100%;
      height: 320px;
    }

    .image {
      width: 101%;
      height: 100%;
      max-width: 440px;
      margin: 0 auto;
      background-size: contain;
    }

    .foot {
      width: 100%;
    }

    .foot-title {
      font-family: 'Proxima Nova';
      font-size: 36px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      text-align: center;
      color: $text-color;
    }

    .foot-text {
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: $text-color;

      a {
        @include font(18px, normal, 1, $andes-blue-500);
      }
    }
  }
}

@media (width >= 1280px) {
  .fs-benefit {
    width: 100%;
    overflow: hidden;
    max-width: none;

    .right {
      width: 48.076923%;
      max-width: 500px;
      height: 320px;
      float: right;
    }

    .image {
      width: 101%;
      height: 100%;
      max-width: 500px;
      margin: 0 auto;
      background-size: contain;
    }

    .foot {
      padding-top: 58px;
      width: 48.0769%;
      max-width: 500px;
      float: left;
    }

    .foot-title {
      font-family: 'Proxima Nova';
      color: $text-color;
      text-align: left;
    }

    .foot-text {
      max-width: 420px;
      text-align: left;
      color: $text-color;
    }
  }

  .fs-benefit:first-child {
    margin-bottom: 26px;
  }
}
