.integrate {
  min-height: 288px;
  position: relative;
  padding: 64px 0;
  box-sizing: border-box;
  background-repeat: no-repeat, repeat-x;
  background-size: cover;

  &.box {
    background-image: url('~@assets/images/envios/integrate/footer-yellow.jpeg');
    background-position-x: 30%;
  }

  .integrate-title {
    @include font(24px, normal, 1.2, #fff);

    margin: auto;
    margin-bottom: 40px;
    width: 312px;
    text-align: center;
  }

  .btn-cont {
    margin: auto;
    width: 185px;
  }

  .btn-white {
    @include font(18px, normal, 1, #3483fa);

    width: 185px;
    height: 48px;
    border-radius: 4px;
    display: table-cell;
    vertical-align: middle;
    box-shadow: 0 7px 7px 0 rgb(40 46 87 / 8%);
    text-align: center;
    margin: 0 auto;
    background-color: #fff;
  }

  .btn-white:hover {
    color: #fff;
    background-color: rgb(52 131 250 / 80%);
    box-shadow: 0 7px 14px 7px rgb(40 46 87 / 16%);
  }
}

@media ($breakpoint-medium) {
  .integrate {
    min-height: 353px;
    position: relative;
    padding: 84px 0;
    box-sizing: border-box;

    &.box {
      background-position-x: 0%;
    }

    .integrate-title {
      @include font(42px, normal, 1.2, #fff);

      margin: auto;
      margin-bottom: 32px;
      width: 750px;
      text-align: center;
    }

    .btn-cont {
      margin: auto;
      width: 185px;
    }

    .btn-white {
      width: 185px;
      height: 54px;
    }
  }
}
