@import '../../fs-benefit/styles/fs-benefit';

.fs-benefits {
  background-color: $andes-white;
  padding-top: 56px;
  padding-bottom: 56px;
}

@media (min-width: $breakpoint-desktop) {
  .fs-benefits {
    position: absolute;
    width: auto;
    box-sizing: border-box;
    top: -64px;
    border-radius: 8px;
    padding: 64px;
    padding-bottom: 0;
    background-color: $andes-white;
    box-shadow: 0 7px 7px 0 rgb(51 51 51 / 8%);
    margin-left: 64px;
    margin-right: 64px;
    left: 0;
    right: 0;
  }
}

@media (width >= 1152px) {
  .fs-benefits {
    width: 1040px;
    margin: auto;
  }
}
