// Select theme mercadolibre
$andes-theme: 'mercadolibre' !default;

// Andes Common Variables
@import '@andes/common/variables';

// Sizes
$mobile-web: 400px;
$small-tablet: 800px;
$large-tablet: 1024px;
$large-desktop: 1280px;
$breakpoint-medium: 'min-width: 768px';
$breakpoint-large: 'min-width: 1024px';
$breakpoint-larger: 'min-width: 1200px';

/////////////////////////////////////////////////////
// TODO: Todo lo que sigue se debe validar contra variables de Andes UI
// y aplicar en todos los files .scss que corresponda.

$gray-darker: #333 !default;
$gray-dark: #666 !default;
$gray-semi-dark: #888 !default;
$gray-light-dark: #9f9f9f !default;
$gray: #999 !default;
$gray-light: #ddd !default;
$gray-lighter: #eee !default;
$gray-lightest: #f0f0f0 !default;
$gray-ultra-light: #f6f6f6 !default;
$gray-ultra-light-f7: #f7f7f7 !default;
$gray-ultra-lighter: #f9f9f9 !default;

// TIMELINE COLORS
$timeline-gray-header: #f7f7f7 !default;
$timeline-gray: #ddd !default;

// RED
$red-dark: #e4185c !default;
$red: #f04449 !default;

// GREEN
$green-dark: #92c273 !default;
$green: #1bb04a !default;
$green-light: #aadb1d !default;

// YELLOW
$yellow-dark: #d7c879 !default;
$yellow: #f57819 !default;
$checkpoint-yellow: #fddb1f !default;
$action-color: #03b !default;
$body-background-gray: #dfdfdf !default;
$text-color: #04204c !default;
$font-family: 'Proxima Nova', arial, helvetica, sans-serif;

@media (width >= 768px) {
  $font-family: arial, helvetica, sans-serif;
}

// FONTS

$font-tiny: 11px;
$font-smaller: 12px;
$font-small: 13px;
$font-medium: 16px;
$font-large: 18px;
$font-larger: 22px;
$font-huge: 42px;
