.faq {
  background: $andes-bg-secondary;
  padding: 93px 0 $andes-spacing-64;

  h2 {
    @include font($font-size-28, normal, 1.15, $text-color);

    text-align: center;
    margin: 0 0 45.5px;
  }

  h3 {
    margin: $andes-spacing-40 $andes-spacing-20;
    text-align: center;
  }

  .container {
    max-width: 1040px;
    margin: auto;
  }

  .accordion {
    margin: 0;
    padding: 0 $andes-spacing-32;

    .accordion-item {
      list-style: none;
      border-bottom: solid 1px $gray-light;
      position: relative;
      margin-bottom: 1px;
      padding-bottom: $andes-spacing-16;
      overflow: hidden;

      label {
        display: block;
        padding: $andes-spacing-32 0 0 0;
        cursor: pointer;
        position: relative;
      }

      &:first-of-type {
        border-top: solid 1px $gray-light;
      }

      h4 {
        @include font($font-size-20, $font-weight-semibold, 1.2, $text-color);

        margin: 0;
        text-align: left;
        position: relative;
        padding-right: 55px;
        padding-bottom: $andes-spacing-16;

        i {
          font-family: loyalty-fonts;
          position: absolute;
          right: 20px;
          top: 0;
          font-style: normal;
          font-size: $font-size-12;
          color: $andes-blue-500;
          font-weight: 100;
          line-height: 24px;
          overflow: hidden;
          transform: rotate(180deg);
          transition: transform 0.15s linear;
        }
      }

      .tab-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.35s ease, padding-bottom 0.35s ease;
        padding-bottom: 0;

        &__answer {
          @include font($font-size-16, normal, normal, $gray-dark);

          padding: 0;
          margin: 0;
          text-align: left;
        }
      }

      ul {
        margin-left: $andes-spacing-12;
        padding-left: $andes-spacing-40;

        li {
          color: $gray-dark;
        }
      }

      .tab-content__answer {
        @include font($font-size-16, normal, normal, $gray-dark);

        padding: 0;
        margin: 0;

        a {
          color: $andes-blue-500;
        }
      }
    }

    .accordion-check {
      display: none;

      &:checked ~ .tab-content {
        max-height: 20em;
        padding-bottom: $andes-spacing-16;
      }

      &:checked ~ label {
        i {
          transform: rotate(0deg);
        }
      }
    }
  }

  .link {
    font-size: $font-size-16;
    line-height: 1.25;
    text-align: left;
    color: $andes-blue-500;
    display: block;
    text-decoration: none;
    font-weight: $font-weight-semibold;
    margin: $andes-spacing-48 0;
    padding: 0 $andes-spacing-20;
  }

  .tyc {
    padding: $andes-spacing-32 $andes-spacing-24 0 $andes-spacing-24;

    a {
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      font-size: $font-size-16;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .faq {
    padding: 120px $andes-spacing-64 120px;

    .accordion {
      padding: 0;

      .accordion-item h4 {
        @include font($font-size-20, $font-weight-semibold, 1.2, $text-color);
      }

      .accordion-item label {
        padding: $andes-spacing-32 5px 0 $andes-spacing-24;
      }

      .accordion-item ul {
        li {
          .tab-content {
            @include font($font-size-20, normal, 1.2, $gray-dark);
          }
        }
      }

      .accordion-item .tab-content__answer {
        @include font($font-size-20, normal, 1.2, $gray-dark);

        padding: 0 60px 0 $andes-spacing-24;
      }
    }
  }
}

@media (width >= 1280px) {
  .faq h2 {
    margin-bottom: 80px;
  }
}

@media (max-width: $mobile-web) {
  .word-break {
    word-break: break-all;
  }
}
